import { XF } from "./XF";
import { loadChildGalleries } from "./california-gallery";

XF.Element.extend("preview-click", {
  onLoad(data) {
    if (!data.html) {
      return;
    }

    const $container = this.getCurrentContainer();

    if (data.html.content) {
      XF.setupHtmlInsert(data.html, ($html, container, onComplete) => {
        $container.removeClassTransitioned("is-active", () => {
          $container.replaceWith($html);
          onComplete();
          $html.addClassTransitioned("is-active");
          loadChildGalleries($html);
        });

        // Element inside JQuery element triggers event handled by nested post reply UI
        this.$form[0].dispatchEvent(new Event("preview:shown"));

        return false;
      });
    } else {
      $container.xfFadeUp(XF.config.speed.fast);
    }
  },
});

XF.Event.extend("switch", {
  eventType: "click",
  eventNameSpace: "XFSwitchClick",
  options: {
    redirect: false,
    label: ".js-label",
  },

  processing: false,

  init() {
    this.$target.data("label", this.options.label);
  },

  click(e) {
    e.preventDefault();

    if (this.processing) {
      return;
    }
    this.processing = true;

    const href = this.$target.attr("href");

    XF.ajax("post", href, {}, XF.proxy(this, "handleAjax"), {
      skipDefaultSuccess: true,
    }).always(() => {
      setTimeout(() => {
        this.processing = false;
      }, 250);
    });
  },

  handleAjax(data) {
    const { $target } = this;
    const event = $.Event("switchclick:complete");

    $target.trigger(event, data, this);
    if (event.isDefaultPrevented()) {
      return;
    }

    // data-sk-sync can be set on links with switch click to sync their status while making only one ajax call for the link
    // that was actually clicked on (eg. follow vs unfollow a user)
    const skSync = this.$target.data("sk-sync");
    if (skSync) {
      const targetElements = document.querySelectorAll(
        `a[href='${this.$target.attr("href")}'][data-sk-sync='${skSync}']`
      );

      for (let i = 0; i < targetElements.length; i++) {
        XF.handleSwitchResponse(
          $(targetElements[i]),
          data,
          this.options.redirect
        );
      }
    } else {
      XF.handleSwitchResponse($target, data, this.options.redirect);
    }
  },
});

XF.Element.extend("reaction", {
  actionClick(e: Event) {
    if (XF.config.userId !== 0) {
      Object.getPrototypeOf(Object.getPrototypeOf(this)).actionClick.call(
        this,
        e
      );
    }
  },
});
